import { React, useState } from "react";

import partner from "../../../../api/partner";
import authHeader from "../../../../api/authHeader";

import HandleError from "../../../../components/functions/HandleError";
import HandleToast from "../../../../components/functions/HandleToast";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  Chip,
  Grid,
  Icon,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ToggleButtonGroup,
  ToggleButton,
  Drawer,
} from "@mui/material";
import { Close, Delete, Edit, InsertDriveFile, Upload, PermMedia } from "@mui/icons-material";
import FileUpload from "./FileUpload";

function CorporateDocuments({
  entityData,
  setManageProfile,
  getEntityData,
  getProfileData,
  getCorporateDocuments,
}) {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [processing, setProcessing] = useState(false);
  const [documentType, setDocumentType] = useState(1);
  const [file, setFile] = useState(null);

  const processUpload = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    formData.append("corporation_uuid", entityData.value.corporation_uuid);
    formData.append("document_type", documentType);

    try {
      const response = await partner.post(
        "/v1/entity/corporations/corporation/corporate-document/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "partners",
          },
          onUploadProgress: function (progressEvent) {},

          //withCredentials: true,
        }
      );

      getCorporateDocuments();
      getProfileData();
      getEntityData();

      displayToast("Document uploaded successfully", () => {
        setProcessing(false);
        setFile(null);
        setDocumentType(1);
        setManageProfile(false);
      });
    } catch (err) {
      console.log(err);
      handleError(err);
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="body2">Select the type of document you wish to upload</Typography>

      <ToggleButtonGroup
        color={"primary"}
        value={documentType}
        exclusive
        onChange={(e, value) => {
          if (value !== null) setDocumentType(value);
        }}
      >
        <ToggleButton value={1}>Certificate of incorporation</ToggleButton>
        <ToggleButton value={2}>Memorandum and articles of association</ToggleButton>
      </ToggleButtonGroup>

      <FileUpload setFile={setFile} />

      {file && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,

              border: 1,
              borderColor: "divider",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      fontWeight: 600,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        pl: 2,
                      }}
                    >
                      <PermMedia />
                    </Box>
                    <Box
                      sx={{
                        borderRight: 1,
                        borderLeft: 1,
                        borderColor: "divider",
                        p: 1,
                      }}
                    >
                      <Typography variant="">Filename</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    fontSize: 13,
                    pl: 2,
                  }}
                >
                  {file.path}
                </Box>
              </Box>

              <Box sx={{ pr: 1 }}>
                <IconButton
                  title="Remove file"
                  onClick={() => {
                    setFile(null);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              disabled={file == null ? true : false}
              variant="contained"
              color="success"
              loading={processing}
              onClick={(e) => {
                processUpload(e);
              }}
            >
              Upload Document
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  );
}

export default CorporateDocuments;
