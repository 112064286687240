import { React, useEffect, useState } from "react";

import central from "../../../api/central";
import authHeader from "../../../api/hostedAuthHeader";

import HandleError from "../../../components/functions/HostedHandleError";
import HandleToast from "../../../components/functions/HandleToast";

import Lottie from "react-lottie";
import loadingLottie from "../../../components/lotties/loading-dots.json";

import { MuiTelInput } from "mui-tel-input";

import { Close, Edit } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Telephone = ({
  partner,
  caseUUID,
  entityData,
  caseData,
  activeView,
  getPerson,
  requestData,
  getRequestData,
  getCase,
}) => {
  const useAuthHeader = authHeader();
  const handleError = HandleError();
  const displayToast = HandleToast();

  const [isLoaded, setIsLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
  };

  const [editMode, setEditMode] = useState(false);

  const [telephoneData, setTelephoneData] = useState(null);
  const [diallingCode, setDiallingCode] = useState("");
  const [phoneCountry, setPhoneCountry] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [phoneValue, setPhoneValue] = useState("");

  const [validateTelephone, setValidateTelephone] = useState(false);
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const [code5, setCode5] = useState("");
  const [code6, setCode6] = useState("");

  const getPersonTelephoneData = async (personUUID) => {
    try {
      const response = await partner.post(
        `/v1/entity/people/person/data/telephone-number`,
        JSON.stringify({
          person_uuid: personUUID,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      setTelephoneData(response.data);

      if (response.data.telephone_number != "") {
        setDiallingCode(response.data.dialling_code);
        setPhoneCountry(response.data.country.code);
        setTelephoneNumber(response.data.telephone_number);
        setPhoneValue("+" + response.data.dialling_code + response.data.telephone_number);

        if (
          response.data.telephone_number != "" &&
          !caseData.value.telephone_verification &&
          caseData.value.options.telephone_verification
        ) {
          setValidateTelephone(true);
        }
      } else {
        setEditMode(true);
      }

      setIsLoaded(true);
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const handleUpdateTelephoneNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);

    try {
      const response = await partner.post(
        `/v1/entity/people/person/telephone-number/update`,
        JSON.stringify({
          person_uuid: entityData.value.person_uuid,
          telephone_number: telephoneNumber,
          dialling_code: diallingCode,
          telephone_country: phoneCountry,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },

          //withCredentials: true,
        }
      );

      getPersonTelephoneData(entityData.value.person_uuid);
      getRequestData();
      getCase();

      displayToast(`Telephone number updated successfully`, () => {
        setProcessing(false);

        if (caseData.value.options.telephone_verification) {
          setValidateTelephone(true);
        } else {
          setEditMode(false);
        }
      });
    } catch (err) {
      handleError(err);
      setProcessing(false);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setProcessing(true);

    console.log(caseUUID);

    try {
      const response = await central.post(
        "/verify-telephone/process",
        JSON.stringify({
          token: entityData.value.person_uuid,
          code: code1 + code2 + code3 + code4 + code5 + code6,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + useAuthHeader.base64encodedData,
            service: "hosted",
          },
          mode: "no-cors",
          //withCredentials: true,
        }
      );

      getPersonTelephoneData(entityData.value.person_uuid);
      getRequestData();
      getCase();

      displayToast(`Telephone number verified successfully`, () => {
        setValidateTelephone(false);
        setEditMode(false);
        setProcessing(false);
      });
    } catch (err) {
      console.log(err);
      setCode1("");
      setCode2("");
      setCode3("");
      setCode4("");
      setCode5("");
      setCode6("");
      document.getElementById("input1").focus();
      handleError(err);
    }
  };

  useEffect(() => {
    getPersonTelephoneData(entityData.value.person_uuid);
  }, []);

  return isLoaded ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          <Typography variant="subtitle2">Telephone number</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 2 }}>
          {telephoneData?.telephone_number != "" && (
            <IconButton
              title="Edit"
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              {editMode ? <Close /> : <Edit />}
            </IconButton>
          )}
        </Box>
      </Box>

      {!editMode ? (
        <Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", p: 2 }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle1" sx={{ fontStyle: "italic", fontSize: "13px" }}>
                Telephone number
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography variant="subtitle2">
                {telephoneData?.telephone_number != ""
                  ? "+" +
                    telephoneData?.dialling_code +
                    " " +
                    telephoneData?.telephone_number +
                    " (" +
                    telephoneData?.country?.name +
                    ")"
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
            <Button
              variant="contained"
              size=""
              color="primary"
              onClick={() => {
                requestData.requirements.citizenship
                  ? (activeView.value = 4)
                  : requestData.requirements.address
                  ? (activeView.value = 5)
                  : requestData.requirements.identification
                  ? (activeView.value = 6)
                  : requestData.requirements.proof_of_life
                  ? (activeView.value = 7)
                  : requestData.requirements.pep_declaration
                  ? (activeView.value = 8)
                  : (activeView.value = 9);
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {validateTelephone ? (
            <Box sx={{ width: "100%", p: 2 }}>
              <Typography
                sx={{ mb: 2, textTransform: "uppercase" }}
                align="center"
                variant="h6"
                gutterBottom
              >
                <strong>Verifying your telephone number</strong>
              </Typography>
              <Typography sx={{ mb: 2 }} align="center" variant="body2" gutterBottom>
                Please enter the 6-digit code we sent to your telephone number
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  textAlign: "center",
                }}
              >
                <TextField
                  value={code1}
                  id="input1"
                  onChange={(e) => setCode1(e.target.value)}
                  type="number"
                  variant="outlined"
                  autoFocus
                  size="large"
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    style: { textAlign: "center", fontSize: "30px" },
                  }}
                  sx={{ width: "100px" }}
                  onKeyUp={(e) => {
                    if (e.target.value.length === 1) {
                      document.getElementById("input2").focus();
                    }
                  }}
                />
                <TextField
                  id="input2"
                  value={code2}
                  onChange={(e) => setCode2(e.target.value)}
                  type="number"
                  variant="outlined"
                  size="large"
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    style: { textAlign: "center", fontSize: "30px" },
                  }}
                  sx={{ width: "100px" }}
                  onKeyUp={(e) => {
                    if (e.target.value.length === 1) {
                      document.getElementById("input3").focus();
                    } else if (e.key === "Backspace") {
                      document.getElementById("input1").focus();
                    }
                  }}
                />
                <TextField
                  id="input3"
                  value={code3}
                  onChange={(e) => setCode3(e.target.value)}
                  type="number"
                  variant="outlined"
                  size="large"
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    style: { textAlign: "center", fontSize: "30px" },
                  }}
                  sx={{ width: "100px" }}
                  onKeyUp={(e) => {
                    if (e.target.value.length === 1) {
                      document.getElementById("input4").focus();
                    } else if (e.key === "Backspace") {
                      document.getElementById("input2").focus();
                    }
                  }}
                />
                <TextField
                  id="input4"
                  value={code4}
                  onChange={(e) => setCode4(e.target.value)}
                  type="number"
                  variant="outlined"
                  size="large"
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    style: { textAlign: "center", fontSize: "30px" },
                  }}
                  sx={{ width: "100px" }}
                  onKeyUp={(e) => {
                    if (e.target.value.length === 1) {
                      document.getElementById("input5").focus();
                    } else if (e.key === "Backspace") {
                      document.getElementById("input3").focus();
                    }
                  }}
                />
                <TextField
                  id="input5"
                  value={code5}
                  onChange={(e) => setCode5(e.target.value)}
                  type="number"
                  variant="outlined"
                  size="large"
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    style: { textAlign: "center", fontSize: "30px" },
                  }}
                  sx={{ width: "100px" }}
                  onKeyUp={(e) => {
                    if (e.target.value.length === 1) {
                      document.getElementById("input6").focus();
                    } else if (e.key === "Backspace") {
                      document.getElementById("input4").focus();
                    }
                  }}
                />
                <TextField
                  id="input6"
                  value={code6}
                  onChange={(e) => setCode6(e.target.value)}
                  variant="outlined"
                  size="large"
                  inputProps={{
                    maxLength: 1,
                    pattern: "[0-9]*",
                    style: { textAlign: "center", fontSize: "30px" },
                  }}
                  sx={{ width: "100px" }}
                  onKeyUp={(e) => {
                    if (e.target.value.length === 1) {
                      handleVerification(e);
                    } else if (e.key === "Backspace") {
                      document.getElementById("input5").focus();
                    }
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                <MuiTelInput
                  fullWidth
                  defaultCountry="IM"
                  value={phoneValue}
                  onChange={(phone, meta) => {
                    setTelephoneNumber(meta.nationalNumber);
                    setPhoneCountry(meta.countryCode);
                    setDiallingCode(meta.countryCallingCode);
                    setPhoneValue(phone);
                  }}
                />
              </Box>

              <Divider />

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", p: 2 }}>
                <LoadingButton
                  disableElevation
                  variant="contained"
                  loading={processing}
                  color="success"
                  onClick={(e) => {
                    handleUpdateTelephoneNumber(e);
                  }}
                >
                  Save changes
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  ) : (
    <Box>
      <Lottie speed={2.0} options={defaultOptions} height={200} width={200} />
    </Box>
  );
};

export default Telephone;
